import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import translationDE from './translations/translationDE.json';
import translationEN from './translations/translationEN.json';
import translationES from './translations/translationES.json';
import translationFR from './translations/translationFR.json';
import translationIT from './translations/translationIT.json';
import translationPT from './translations/translationPT.json';
import translationSK from './translations/translationSK.json';

// Import Maderotranslations
import maderoDE from './translations/madero/maderoDE.json';
import maderoEN from './translations/madero/maderoEN.json';
import maderoES from './translations/madero/maderoES.json';
import maderoFR from './translations/madero/maderoFR.json';
import maderoIT from './translations/madero/maderoIT.json';
import maderoPT from './translations/madero/maderoPT.json';
import maderoSK from './translations/madero/maderoSK.json';

// Orient Sugaring sugaringtranslations
import sugaringDE from './translations/sugaring/sugaringDE.json';
import sugaringEN from './translations/sugaring/sugaringEN.json';
import sugaringES from './translations/sugaring/sugaringES.json';
import sugaringFR from './translations/sugaring/sugaringFR.json';
import sugaringIT from './translations/sugaring/sugaringIT.json';
import sugaringPT from './translations/sugaring/sugaringPT.json';
import sugaringSK from './translations/sugaring/sugaringSK.json';

// Gesichtsbehandlung gesichttranslations
import gesichtDE from './translations/gesicht/gesichtDE.json';
import gesichtEN from './translations/gesicht/gesichtEN.json';
import gesichtES from './translations/gesicht/gesichtES.json';
import gesichtFR from './translations/gesicht/gesichtFR.json';
import gesichtIT from './translations/gesicht/gesichtIT.json';
import gesichtPT from './translations/gesicht/gesichtPT.json';
import gesichtSK from './translations/gesicht/gesichtSK.json';

// Wimpern gesichttranslations
import wimpernDE from './translations/wimpern/wimpernDE.json';
import wimpernEN from './translations/wimpern/wimpernEN.json';
import wimpernES from './translations/wimpern/wimpernES.json';
import wimpernFR from './translations/wimpern/wimpernFR.json';
import wimpernIT from './translations/wimpern/wimpernIT.json';
import wimpernPT from './translations/wimpern/wimpernPT.json';
import wimpernSK from './translations/wimpern/wimpernSK.json';

// Wimpern Microneedling
import microneedlingDE from './translations/microneedling/microneedlingDE.json';
import microneedlingEN from './translations/microneedling/microneedlingEN.json';
import microneedlingES from './translations/microneedling/microneedlingES.json';
import microneedlingFR from './translations/microneedling/microneedlingFR.json';
import microneedlingIT from './translations/microneedling/microneedlingIT.json';
import microneedlingPT from './translations/microneedling/microneedlingPT.json';
import microneedlingSK from './translations/microneedling/microneedlingSK.json';

// Haarentfernung Laser
import haarentfernungLaserDE from './translations/laser/haarentfernungLaserDE.json';
import haarentfernungLaserEN from './translations/laser/haarentfernungLaserEN.json';
import haarentfernungLaserES from './translations/laser/haarentfernungLaserES.json';
import haarentfernungLaserFR from './translations/laser/haarentfernungLaserFR.json';
import haarentfernungLaserIT from './translations/laser/haarentfernungLaserIT.json';
import haarentfernungLaserPT from './translations/laser/haarentfernungLaserPT.json';
import haarentfernungLaserSK from './translations/laser/haarentfernungLaserSK.json';

// Nadelepilation mit Apilus
import nadelepilationDE from './translations/nadelepilation/nadelepilationDE.json';
import nadelepilationEN from './translations/nadelepilation/nadelepilationEN.json';
import nadelepilationES from './translations/nadelepilation/nadelepilationES.json';
import nadelepilationFR from './translations/nadelepilation/nadelepilationFR.json';
import nadelepilationIT from './translations/nadelepilation/nadelepilationIT.json';
import nadelepilationPT from './translations/nadelepilation/nadelepilationPT.json';
import nadelepilationSK from './translations/nadelepilation/nadelepilationSK.json';

// Jordishape
import jordishapeDE from './translations/jordishape/jordishapeDE.json';
import jordishapeEN from './translations/jordishape/jordishapeEN.json';
import jordishapeES from './translations/jordishape/jordishapeES.json';
import jordishapeFR from './translations/jordishape/jordishapeFR.json';
import jordishapeIT from './translations/jordishape/jordishapeIT.json';
import jordishapePT from './translations/jordishape/jordishapePT.json';
import jordishapeSK from './translations/jordishape/jordishapeSK.json';

// Hot-Stone
import hotstoneDE from './translations/hotstone/hotstoneDE.json';
import hotstoneEN from './translations/hotstone/hotstoneEN.json';
import hotstoneES from './translations/hotstone/hotstoneES.json';
import hotstoneFR from './translations/hotstone/hotstoneFR.json';
import hotstoneIT from './translations/hotstone/hotstoneIT.json';
import hotstonePT from './translations/hotstone/hotstonePT.json';
import hotstoneSK from './translations/hotstone/hotstoneSK.json';

// Vita Skin
import vitaskinDE from './translations/vitaskin/vitaskinDE.json';
import vitaskinEN from './translations/vitaskin/vitaskinEN.json';
import vitaskinES from './translations/vitaskin/vitaskinES.json';
import vitaskinFR from './translations/vitaskin/vitaskinFR.json';
import vitaskinIT from './translations/vitaskin/vitaskinIT.json';
import vitaskinPT from './translations/vitaskin/vitaskinPT.json';
import vitaskinSK from './translations/vitaskin/vitaskinSK.json';

// Nagelstudio Sandra
import sandraDE from './translations/sandra/sandraDE.json';
import sandraEN from './translations/sandra/sandraEN.json';
import sandraES from './translations/sandra/sandraES.json';
import sandraFR from './translations/sandra/sandraFR.json';
import sandraIT from './translations/sandra/sandraIT.json';
import sandraPT from './translations/sandra/sandraPT.json';
import sandraSK from './translations/sandra/sandraSK.json';

// prxt33
import prxt33DE from './translations/prxt33/prxt33DE.json';
import prxt33EN from './translations/prxt33/prxt33EN.json';
import prxt33ES from './translations/prxt33/prxt33ES.json';
import prxt33FR from './translations/prxt33/prxt33FR.json';
import prxt33IT from './translations/prxt33/prxt33IT.json';
import prxt33PT from './translations/prxt33/prxt33PT.json';
import prxt33SK from './translations/prxt33/prxt33SK.json';

const resources = {
  de: {
    translation: translationDE,
    madero: maderoDE,
    sugaring: sugaringDE,
    gesicht: gesichtDE,
    wimpern: wimpernDE,
    microneedling: microneedlingDE,
    haarentfernungLaser: haarentfernungLaserDE,
    nadelepilation: nadelepilationDE,
    jordishape: jordishapeDE,
    hotstone: hotstoneDE,
    vitaskin: vitaskinDE,
    sandra: sandraDE,
    prxt33: prxt33DE,
  },
  en: {
    translation: translationEN,
    madero: maderoEN,
    sugaring: sugaringEN,
    gesicht: gesichtEN,
    wimpern: wimpernEN,
    microneedling: microneedlingEN,
    haarentfernungLaser: haarentfernungLaserEN,
    nadelepilation: nadelepilationEN,
    jordishape: jordishapeEN,
    hotstone: hotstoneEN,
    vitaskin: vitaskinEN,
    sandra: sandraEN,
    prxt33: prxt33EN,
  },
  es: {
    translation: translationES,
    madero: maderoES,
    sugaring: sugaringES,
    gesicht: gesichtES,
    wimpern: wimpernES,
    microneedling: microneedlingES,
    haarentfernungLaser: haarentfernungLaserES,
    nadelepilation: nadelepilationES,
    jordishape: jordishapeES,
    hotstone: hotstoneES,
    vitaskin: vitaskinES,
    sandra: sandraES,
    prxt33: prxt33ES,
  },
  fr: {
    translation: translationFR,
    madero: maderoFR,
    sugaring: sugaringFR,
    gesicht: gesichtFR,
    wimpern: wimpernFR,
    microneedling: microneedlingFR,
    haarentfernungLaser: haarentfernungLaserFR,
    nadelepilation: nadelepilationFR,
    jordishape: jordishapeFR,
    hotstone: hotstoneFR,
    vitaskin: vitaskinFR,
    sandra: sandraFR,
    prxt33: prxt33FR,
  },
  it: {
    translation: translationIT,
    madero: maderoIT,
    sugaring: sugaringIT,
    gesicht: gesichtIT,
    wimpern: wimpernIT,
    microneedling: microneedlingIT,
    haarentfernungLaser: haarentfernungLaserIT,
    nadelepilation: nadelepilationIT,
    jordishape: jordishapeIT,
    hotstone: hotstoneIT,
    vitaskin: vitaskinIT,
    sandra: sandraIT,
    prxt33: prxt33IT,
  },
  pt: {
    translation: translationPT,
    madero: maderoPT,
    sugaring: sugaringPT,
    gesicht: gesichtPT,
    wimpern: wimpernPT,
    microneedling: microneedlingPT,
    haarentfernungLaser: haarentfernungLaserPT,
    nadelepilation: nadelepilationPT,
    jordishape: jordishapePT,
    hotstone: hotstonePT,
    vitaskin: vitaskinPT,
    sandra: sandraPT,
    prxt33: prxt33PT,
  },
  sk: {
    translation: translationSK,
    madero: maderoSK,
    sugaring: sugaringSK,
    gesicht: gesichtSK,
    wimpern: wimpernSK,
    microneedling: microneedlingSK,
    haarentfernungLaser: haarentfernungLaserSK,
    nadelepilation: nadelepilationSK,
    jordishape: jordishapeSK,
    hotstone: hotstoneSK,
    vitaskin: vitaskinSK,
    sandra: sandraSK,
    prxt33: prxt33SK,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de', // Standardmäßig eingestellte Sprache
    fallbackLng: 'de',
    ns: ['translation', 'madero'], // Namespaces hinzufügen
    defaultNS: 'translation', // Standard-namespace
    interpolation: {
      escapeValue: false, // React benötigt kein Escaping
    },
  });

export default i18n;
