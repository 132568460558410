import React from 'react';
import './About.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <div className='about'>
      <h1>{t('about')}</h1>
    </div>
  );
}

export default About;