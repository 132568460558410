import React, { useEffect } from 'react';
import './TopBar.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaPhone, FaClock, FaMapMarkedAlt, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { SiWhatsapp } from 'react-icons/si';
import './i18n';

function TopBar() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const topbar = document.querySelector('.TopBar');
      if (window.scrollY > 50) {
        topbar.style.backgroundColor = 'var(--background-color)';
      } else {
        topbar.style.backgroundColor = 'transparent';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="TopBar">
      <div className="container">
        <div className="social">
          <a href="https://www.instagram.com/bodybeautyline/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icon" />
          </a>
          <a href="https://www.facebook.com/bodybeautyline/" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/katarina-batsilas-285b09bb/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn className="icon" />
          </a>
        </div>
        <div className="adress">
          <FaMapMarkedAlt className="icon-small" />
          <div>
            <span>{t('adress')}</span>
          </div>
        </div>
        <div className="tel">
          <FaPhone className="icon-small" />
          <div>
            <span>{t('tel_number')}</span>
          </div>
        </div>
        <div className="open">
          <FaClock className="icon-small" />
          <div>
            <span>{t('monday_to_saturday')}</span>
          </div>
        </div>
        <div className="whatsapp">
          <a href="https://api.whatsapp.com/send?phone=41796896880" target="_blank" rel="noopener noreferrer">
            <SiWhatsapp className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
