import React from 'react';
import './Booking.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';

function Booking() {
  const { t } = useTranslation();

  return (
    <div className='booking'>
      <h1>{t('booking')}</h1>
      
    </div>
  );
}

export default Booking;