import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import germanFlag from './images/flags/de.svg';
import englishFlag from './images/flags/gb.svg';
import spainFlag from './images/flags/es.svg';
import frenchFlag from './images/flags/fr.svg';
import italienFlag from './images/flags/it.svg';
import potugeseFlag from './images/flags/pt.svg';
import slovakFlag from './images/flags/sk.svg';

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const languageSwitcherRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageSwitcherRef.current && !languageSwitcherRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="language-switcher" ref={languageSwitcherRef}>
      <button onClick={toggleDropdown} className="dropdown-button">
        {i18n.language.toUpperCase()}
      </button>
      {dropdownOpen && (
        <div className="dropdown-menu">
          <button onClick={() => changeLanguage('de')}>
            <img src={germanFlag} alt={t('de')} className="flag-icon" />
            <span>{t('de')}</span>
          </button>
          <button onClick={() => changeLanguage('en')}>
            <img src={englishFlag} alt={t('en')} className="flag-icon" />
            <span>{t('en')}</span>
          </button>
          <button onClick={() => changeLanguage('es')}>
            <img src={spainFlag} alt={t('es')} className="flag-icon" />
            <span>{t('es')}</span>
          </button>
          <button onClick={() => changeLanguage('fr')}>
            <img src={frenchFlag} alt={t('fr')} className="flag-icon" />
            <span>{t('fr')}</span>
          </button>
          <button onClick={() => changeLanguage('it')}>
            <img src={italienFlag} alt={t('it')} className="flag-icon" />
            <span>{t('it')}</span>
          </button>
          <button onClick={() => changeLanguage('pt')}>
            <img src={potugeseFlag} alt={t('pt')} className="flag-icon" />
            <span>{t('pt')}</span>
          </button>
          <button onClick={() => changeLanguage('sk')}>
            <img src={slovakFlag} alt={t('sk')} className="flag-icon" />
            <span>{t('sk')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
