import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './Styles.css';
import './Styles-Background.css';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

/* Navigation Pages */
import NavBar from './NavBar';
import TopBar from './TopBar';

/* Main Pages */
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
import Datenschutz from './pages/Datenschutz';
import Impressum from './pages/Impressum';

/* Services Pages */
import Gesichtsbehandlungen from './services/Gesichtsbehandlungen';
import HaarentfernungLaser from './services/HaarentfernungLaser';
import HotStone from './services/HotStone';
import Jordishape from './services/Jordishape';
import MaderoTherapie from './services/MaderoTherapie';
import Microneedling from './services/Microneedling';
import Nadelepilation from './services/Nadelepilation';
import NagelstudioSandra from './services/NagelstudioSandra';
import PRXT33 from './services/PRXT33';
import Sugaring from './services/Sugaring';
import VitaSkin from './services/VitaSkin';
import Wimpern from './services/Wimpern';

/* Local Business Schema */
import LocalBusinessSchema from './LocalBusinessSchema';

/* Google Analytics */
import GoogleAnalytics from './GoogleAnalytics';
/*
const changeFavicon = (link) => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon.href = link;
  } else {
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.href = link;
    document.head.appendChild(newFavicon);
  }
};
*/
function App() {
  const { t } = useTranslation();

  const [showTopBar, setShowTopBar] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = "Body-Beautyline";
  }, []);

  /*
  // Change the favicon dynamically
  useEffect(() => {
    changeFavicon('%PUBLIC_URL%/favicon.ico');
  }, []);
*/
  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>{t('app_meta_title')}</title>
          <meta name="description" content={t('app_meta_description')} />
          <meta name="keywords" content={t('app_meta_keywords')} />
        </Helmet>
        <GoogleAnalytics /> {/* Google Analytics einfügen */}
        <LocalBusinessSchema /> {/* Local Business Schema einfügen */}
        <Router>
          <DynamicBackground>
            <TopBar className={showTopBar ? '' : 'hidden'} />
            <NavBar showTopBar={showTopBar} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/gesichtsbehandlungen" element={<Gesichtsbehandlungen />} />
              <Route path="/haarentfernung-laser" element={<HaarentfernungLaser />} />
              <Route path="/hot-stone" element={<HotStone />} />
              <Route path="/jordishape" element={<Jordishape />} />
              <Route path="/madero-therapie" element={<MaderoTherapie />} />
              <Route path="/microneedling" element={<Microneedling />} />
              <Route path="/nadelepilation" element={<Nadelepilation />} />
              <Route path="/nagelstudio-sandra" element={<NagelstudioSandra />} />
              <Route path="/prx-t33" element={<PRXT33 />} />
              <Route path="/sugaring" element={<Sugaring />} />
              <Route path="/vita-skin" element={<VitaSkin />} />
              <Route path="/wimpern" element={<Wimpern />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/impressum" element={<Impressum />} />
            </Routes>
          </DynamicBackground>
        </Router>
      </div>
    </HelmetProvider>
  );
}

function DynamicBackground({ children }) {
  const location = useLocation();
  let backgroundClass = '';

  switch (location.pathname) {
    case '/':
      backgroundClass = 'home-background';
      break;
    case '/about':
      backgroundClass = 'about-background';
      break;
    case '/services':
      backgroundClass = 'services-background';
      break;
    case '/contact':
      backgroundClass = 'contact-background';
      break;
    case '/booking':
      backgroundClass = 'booking-background';
      break;
    default:
      backgroundClass = 'default-background';
      break;
  }

  return <div className={backgroundClass}>{children}</div>;
}

export default App;
